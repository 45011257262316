import { Link, styled } from '@mui/material';

const WeightyLink = styled(Link)(() => ({
    paddingLeft: 4,
    color: '#868788',
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
    },
    marginTop: 7,
}));

export { WeightyLink };
