import { styled } from '@mui/material';

import { StyledButtonBase } from './StyledButtonBase';

const UnsupportedNetwork = styled(StyledButtonBase)(({ theme }) => ({
    padding: theme.spacing(3),
    fontSize: 20,
    fontWeight: 500,
    width: '45%',
    textTransform: 'none',
    color: '#FFF',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
        width: '43%',
        height: 100,
    },
}));

export { UnsupportedNetwork };
