import { UserCredentials } from '../../shared/types/userCredentials';
import { RegisterProps } from '../pages/Register/types/registerProps';

export const getUserCredentialsFromProps = ({
    phoneNumber = '',
    network,
}: Partial<RegisterProps>): UserCredentials => ({
    fullName: undefined,
    email: undefined,
    phoneNumber,
    network,
    password: '',
    confirmPassword: '',
});
