import Axios from 'axios';

import { AuthSession } from '../types/authSession';
import { HeaderEnrichedAuth } from '../types/headerEnrichedAuth';

const authByHeaderEnrichment = async (
    headerEnrichedAuth: HeaderEnrichedAuth,
): Promise<AuthSession> => {
    try {
        const response = await Axios.post<AuthSession>(
            'https://api.wi-flix.com/auth/v1/header-enriched',
            headerEnrichedAuth,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response.data;
    } catch (e) {
        console.error(e);
        throw new Error('Failed to authenticate user');
    }
};

export { authByHeaderEnrichment };
