import { styled } from '@mui/material';

export const Box = styled('div')(() => ({
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '0 20px',
}));

export const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
}));

export const Logo = styled('img')(() => ({
    maxWidth: '150px',
    height: 'auto',
}));

export const TextBody = styled('div')(() => ({
    padding: '20px',
    textAlign: 'left',
}));

export const H1 = styled('h1')(() => ({
    fontSize: '20px',
}));

export const H3 = styled('h3')(() => ({
    margin: '30px 0',
    color: 'white',
}));

export const P = styled('p')(() => ({
    fontSize: '12px',
    lineHeight: '2',
}));
