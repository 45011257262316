import { MouseEventHandler } from 'react';
import { styled } from '@mui/material';

import { StyledButtonBase } from './StyledButtonBase';
import { ImageBackdrop } from './ImageBackdrop';

type NetworkProps = {
    imageSrc: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const ImageSrc = styled('span')(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
}));

const Network = ({ onClick, imageSrc }: NetworkProps): JSX.Element => (
    <StyledButtonBase
        onClick={onClick}
        focusRipple
        focusVisibleClassName=".focusVisible"
        style={{
            width: '45%',
        }}
    >
        <ImageSrc
            style={{
                background: `url(${imageSrc})`,
                backgroundPosition: '50%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                display: 'block',
                textIndent: '-9999px',
            }}
        />
        <ImageBackdrop className=".imageBackdrop" />
    </StyledButtonBase>
);

export { Network };
