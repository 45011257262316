import Axios from 'axios';

export const recordPageView = async (affiliateId: string): Promise<void> => {
    await Axios.get(`/affiliate/recordPageView?page_id=${affiliateId}`, {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
        },
    });
};
