import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const SubmitButton = styled(LoadingButton)(() => ({
    textTransform: 'uppercase',
    background: '#ff6c2f',
    fontSize: 16,
    fontWeight: 600,
    color: '#FFF',
    padding: '1.6rem',
    borderRadius: '0.8rem',
    '&:hover': {
        background: '#ff6c2f',
    },
}));

export { SubmitButton };
