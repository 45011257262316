export const MuiButton = {
    styleOverrides: {
        contained: {
            boxShadow:
                '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
            backgroundColor: '#FFFFFF',
            '&:hover': {
                boxShadow:
                    '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                backgroundColor: '#d5d5d5',
            },
        },
    },
};
