import { ChangeEvent, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import ReactPhoneNumberInput, { Country } from 'react-phone-number-input/input';
import { Box, GlobalStyles, styled } from '@mui/material';

import { CountrySelect } from '../CountrySelect';
import { RawInput } from '../RawInput';

const StyledPhoneInput = styled(ReactPhoneNumberInput)({
    '& input': {
        borderRadius: '.8rem',
    },
});

type PhoneNumberInputProps = {
    placeholder?: string;
    error?: string | string[];
    value: string;
    name: string;
    disabled?: boolean;
    defaultCountry: string;
    onChange: (e164PhoneNumber: string) => void;
};

const PhoneNumberInput = ({
    defaultCountry,
    value,
    name,
    error,
    placeholder = 'Enter your phone number',
    disabled,
    onChange,
}: PhoneNumberInputProps): JSX.Element => {
    const [country, setCountry] = useState<string>(defaultCountry);
    const [isEmpty, setEmpty] = useState(!value);

    const ref = useRef<HTMLInputElement>();

    const onKeyUp = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setEmpty(!ref.current || (ref.current && !ref.current.value));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <GlobalStyles
                styles={{
                    '.PhoneInputCountry': {
                        marginRight: 8,
                        verticalAlign: 'middle',
                        fontSize: 18,
                        backgroundColor: 'black',
                    },
                }}
            />
            <StyledPhoneInput
                name={name}
                useNationalFormatForDefaultCountryValue
                country={country as Country}
                onKeyUp={onKeyUp}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputComponent={RawInput}
                error={Boolean(error)}
                helperText={error}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                ref={ref}
                endAdornment={
                    !isEmpty ? <CountrySelect value={country} onChange={setCountry} /> : undefined
                }
                onChange={onChange}
            />
        </Box>
    );
};

export { PhoneNumberInput };
