import { Box, FormControlLabel, styled } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Network } from '../../../shared/enums/network';
import { useFragmentContext } from '../../../shared/helpers/useFragmentContext';
import { FragmentContext } from '../../../shared/types/fragmentContext';
import { CampaignCredentials } from '../../../shared/types/userCredentials';
import { Checkbox } from '../../components/Checkbox';
import { ControlBlock } from '../../components/ControlBlock';
import { ErrorText } from '../../components/ErrorText';
import { FormActions } from '../../components/FormActions/FormActions';
import { Label } from '../../components/Label';
import { Page } from '../../components/Page';
import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { withRoot } from '../../components/Root';
import { SubmitButton } from '../../components/SubmitButton';
import { Subtitle } from '../../components/Subtitle';
import { Title } from '../../components/Title';
import { WeightyLink } from '../../components/WeightyLink';
import { getInitialProps } from './getInitialProps';
import { MatchParams } from './types/matchParams';
import { RegisterProps } from './types/registerProps';
import { validationSchemaForCampaign } from './validationSchemaForCampaign';

const IntegratedCountries = ['NG', 'GH', 'ZM', 'KE'];

const SignupMeta = styled('fieldset')(() => ({
    textAlign: 'center',
    marginTop: 24,
}));

function Register(props: RegisterProps & RouteComponentProps<MatchParams>): JSX.Element {
    const { location, network, phoneNumber } = props;
    const { countryCode } = useFragmentContext<FragmentContext>();
    const history = useHistory();

    const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(true);
    const hasCountrySupport = countryCode
        ? IntegratedCountries.map(entry => entry.toLowerCase()).indexOf(countryCode.toLowerCase()) >
          -1
        : false;

    const search: URLSearchParams = useMemo(() => new URLSearchParams(location.search), [location]);
    const userNetwork = search.get('nw') || network;

    const [submitError, setSubmitError] = useState('');

    const checkoutURL = search.toString()
        ? `https://checkout.wi-flix.com/?${search.toString()}`
        : 'https://checkout.wi-flix.com';

    const { errors, values, handleChange, handleSubmit, isSubmitting } = useFormik({
        initialValues: {
            phoneNumber: '',
            network: userNetwork || Network.OTHER,
        },
        validateOnChange: false,
        validationSchema: validationSchemaForCampaign,
        onSubmit: async (credentials: CampaignCredentials, { setSubmitting }) => {
            try {
                if (!acceptTermsOfUse) throw new Error('Accept Terms and Conditions to proceed');

                setSubmitting(true);
                const { data } = await axios.post(
                    'https://api.wi-flix.com/auth/v1/header-enriched',
                    credentials,
                );

                // eslint-disable-next-line camelcase
                const { access_token, refresh_token, session } = data;

                // Store tokens as cookies (you can also use localStorage if needed)
                Cookies.set('access_token', access_token, {
                    domain: '.wi-flix.com',
                    path: '/',
                    secure: true,
                });
                Cookies.set('refresh_token', refresh_token, {
                    domain: '.wi-flix.com',
                    path: '/',
                    secure: true,
                });
                Cookies.set('__session', session, {
                    domain: '.wi-flix.com',
                    path: '/',
                    secure: true,
                });
                window.open(checkoutURL, '_self');
            } catch (e) {
                if (e instanceof Error) {
                    setSubmitError(e.message);
                    return;
                }
                console.error(e);
                setSubmitError('Something went wrong. Please try again later.');
            }
        },
    });

    const onChange = (name: string, value: string): void => {
        handleChange({ target: { name, value } });
    };

    if (!hasCountrySupport) {
        // Return to home '/'
        // TODO: Require email field to dispatch login credentials
        history.push('/', { replace: true });
    }

    return (
        <Page>
            <Box
                sx={{
                    flex: 1,
                    '& form': {
                        flex: 1,
                    },
                }}
            >
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Title variant="h3">Sign up</Title>

                    <ControlBlock>
                        <Label value="Your Phone Number" />
                        <PhoneNumberInput
                            name="phoneNumber"
                            error={errors.phoneNumber}
                            value={values.phoneNumber}
                            placeholder="Enter your phone number"
                            onChange={value => onChange('phoneNumber', value)}
                            disabled={Boolean(phoneNumber)}
                            defaultCountry={countryCode}
                        />
                    </ControlBlock>

                    <SignupMeta>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={acceptTermsOfUse}
                                    onChange={() => setAcceptTermsOfUse(!acceptTermsOfUse)}
                                    name="terms-of-use"
                                />
                            }
                            label={
                                <Subtitle>
                                    By continuing, you agree to our
                                    <WeightyLink
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open('/terms-and-conditions', '_blank')
                                        }
                                    >
                                        Terms of Service
                                    </WeightyLink>{' '}
                                    &nbsp;and&nbsp;
                                    <WeightyLink
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => window.open('/privacy-policy', '_blank')}
                                    >
                                        Privacy Policy
                                    </WeightyLink>
                                </Subtitle>
                            }
                        />
                    </SignupMeta>

                    <FormActions>
                        <SubmitButton
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            fullWidth
                            variant="contained"
                            type="submit"
                        >
                            Sign Up
                        </SubmitButton>
                    </FormActions>

                    {submitError && <ErrorText error>{submitError}</ErrorText>}

                    <SignupMeta>
                        <Subtitle
                            style={{
                                color: '#868788',
                                paddingLeft: 8,
                            }}
                            variant="subtitle2"
                        >
                            Already Registered?
                            <WeightyLink style={{ color: '#ff6c2f' }} href={checkoutURL}>
                                Sign In
                            </WeightyLink>
                        </Subtitle>
                    </SignupMeta>
                </form>
            </Box>
        </Page>
    );
}

Register.getInitialProps = getInitialProps;
Register.getChunkName = () => {
    return 'Register';
};

const RegisterCampaignRoute = withRoot(Register);

export { RegisterCampaignRoute };
