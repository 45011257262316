const SAFARICOM_ACCESS_TOKEN_URL = 'https://api.wi-flix.com/auth/v1/safaricom-he-access-token';
const MASKED_MSISDN_URL = 'https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn';

interface EncryptedMsisdnResponse {
    ServiceResponse: {
        ResponseHeader: {
            ResponseCode: string;
            ResponseMsg: string;
        };
        ResponseBody: {
            Response: {
                Msisdn: string;
            };
        };
    };
}

export const getEncryptedMsisdn = async () => {
    const tokenResponse = await fetch(SAFARICOM_ACCESS_TOKEN_URL).then(res => {
        if (!res.ok) {
            throw new Error('Internal Server Error');
        }

        return res.json();
    });
    const token = tokenResponse.body?.accessToken;
    if (!token) {
        throw new Error('Internal Server Error');
    }

    const encryptedMSISDNResponse: EncryptedMsisdnResponse = await fetch(MASKED_MSISDN_URL, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept-Encoding': 'application/json',
            'X-App': 'he-partner',
            'x-messageid': '1234',
            'X-Source-System': 'he-partner',
        },
    }).then(res => {
        if (!res.ok) {
            throw new Error('Internal Server Error');
        }

        return res.json();
    });

    const maskedMsisdn = encryptedMSISDNResponse.ServiceResponse.ResponseBody.Response.Msisdn;
    if (!maskedMsisdn) {
        throw new Error(encryptedMSISDNResponse.ServiceResponse.ResponseHeader.ResponseMsg);
    }

    return maskedMsisdn;
};
