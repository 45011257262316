import { ReactNode } from 'react';
import { Box, styled, Tooltip } from '@mui/material';

import { QuestionMark } from '../QuestionMark';

const StyledLabel = styled('label')(() => ({
    display: 'block',
    margin: '-3px 0',
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bbb',
    color: 'rgba(0,0,0,.987)',
    fontSize: 12,
    fontWeight: 400,
    display: 'block',
    letterSpacing: 'normal',
    lineHeight: '16px',
    padding: [9, 12, 7],
    position: 'relative',
    textTransform: 'none',
}));

const QuestionMarkWrapper = styled('span')(() => ({
    display: 'inline-block',
    fontSize: 0,
    position: 'relative',
    height: 8,
    width: 12,
    marginLeft: 4,
    '& svg': {
        height: 12,
        width: 12,
        position: 'absolute',
        left: 0,
        top: -2,
    },
}));

type LabelProps = {
    value: string;
    helpText?: ReactNode;
};

const Label = ({ value, helpText }: LabelProps) => (
    <Box
        sx={{
            display: 'inline-block',
            marginBottom: '1rem',
        }}
    >
        <StyledLabel>
            <span>
                {value}
                {helpText && (
                    <StyledTooltip title={helpText} placement="top">
                        <QuestionMarkWrapper>
                            <QuestionMark />
                        </QuestionMarkWrapper>
                    </StyledTooltip>
                )}
            </span>
        </StyledLabel>
    </Box>
);

export { Label };
