import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

const validationSchemaForCampaign = yup.object().shape({
    phoneNumber: yup
        .string()
        .required('Invalid phone number')
        .test(
            'isValidPhoneNumber',
            'Phone number must be a valid international phone number',
            value => {
                if (!value) return true;

                return isValidPhoneNumber(value);
            },
        ),
});

export { validationSchemaForCampaign };
