/* eslint-disable react/no-unescaped-entities */
import { withRoot } from '../../components/Root';
import { Box, Container, H1, H3, Logo, P, TextBody } from '../../components/TermsAndPolicy';
import logo from '../../images/logo.png';

const Terms = (): JSX.Element => {
    return (
        <Box>
            <Container>
                <Logo src={logo} alt="Logo" />
            </Container>
            <TextBody>
                <H1>Terms and conditions</H1>
                <br />
                <H3>I. General Terms and Conditions of Use accepted by user and /or subscriber</H3>
                <P className="terms-p-style">
                    The subscriber, user and / or beneficiary ("user") of any of the products or
                    services offered under the Wi-flix brand, acknowledges that they have read,
                    sufficiently understood and freely accept the general terms and conditions
                    provided for use and / or sale of products under the Wi-flix brand or name,
                    namely:
                </P>
                <br />
                <P className="terms-p-style">
                    <b>Wi-flix:</b> Wi-flix offers, among other products and services -directly and
                    / or through partners, allies or contractors- a digital platform through an
                    Application (app) that aims to disseminate digital content on the internet or on
                    the internet platform, prior paying a subscription. In this sense, Wi-flix aims
                    to integrate, offer and / or share subscription "digital content" video on
                    demand (SVOD & AVOD) and Live TV channel streams, virtual concept, movie
                    premiere, football matches, major festival broadcast and a lot more
                    entertainment. “products" of any nature and with diverse content, own or from
                    third parties, especially current affairs programs, movies, TV, news , special
                    events, and other types of SVOD entertainment ("SVOD & AVOD content") that are
                    transmitted via the Internet or on the Internet platform to televisions,
                    computers, mobile devices, and other existing or existing devices, connected to
                    the Internet ("mobile devices and / or connected to the Internet"); all at the
                    discretion of Wi-flix.
                </P>
                <br />
                <br />
                <P className="terms-p-style">
                    These Terms and Conditions of Use govern the use and experience of the products
                    and services offered under the Wi-flix brand. As used in these Terms and
                    Conditions of Use, "Wi-flix” products or services", "our service" or "the
                    service" refers to any product or service offered or to be offered by Wi-flix,
                    especially - but not limited to it - to view SVOD & AVOD content, including all
                    the features and functionalities, website and user interfaces (UI), in addition
                    to all the content and software associated with our service.
                </P>
                <br />
                <br />
                <P className="terms-p-style">
                    <b>1. Acceptance of the Terms and Conditions of use:</b>
                </P>
                <P className="terms-p-style">
                    These Terms and Conditions of Use, include the corresponding Privacy Policy,
                    Authorization of Use for end user(s), About cookies and Internet advertising,
                    and govern the use of the service and experience of products offered under the
                    brand Wi-flix. By using, visiting, or browsing Wi-flix's products or services,
                    you are agreeing and accepting these Terms and Conditions of use. If you do not
                    agree with these Terms and Conditions of use, you must not use Wi-flix products
                    or services.
                </P>
                <P className="terms-p-style">
                    Wi-flix Services and Products are provided and offered by Bac-Tech Solutions
                    Limited herein known as “Wi-flix”. or one of its related companies. The company
                    BacTech Solutions Limited that is providing the service to you (called "Wi-flix"
                    in these Terms and Conditions of Use) and with which you are entering into this
                    agreement, depends on the jurisdiction of the country from which Wi-flix
                    products or services are being offered. Check periodically, as the service
                    provider may change from time to time.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>2. Modification of the Terms and Conditions of use.</b>Wi-flix may, from time
                    to time, change these Terms and Conditions of use, including the Privacy Policy,
                    and Terms and Conditions of use of social networks. Such changes will be
                    effective immediately; however, for current Subscribers (Users), such reviews
                    will, unless otherwise indicated, be effective 30 days after publication. We
                    will try to publish the previous versions (including the changes made) of the
                    Terms and Conditions of use, if applicable, of the last 12 months.
                </P>
                <P className="terms-p-style">
                    <b>3. Privacy.</b> Personally identifiable information is subject to our Privacy
                    Policy, the terms and conditions of which are incorporated into this document,
                    as an integral part of it. Please review our Privacy Policy to understand our
                    practices.
                </P>
                <P className="terms-p-style">
                    <b>4. Preferences in communication.</b> By using Wi-flix products or services,
                    you agree to receive some electronic communications sent by Wi-flix. These
                    communications may include sending messages to the email address indicated
                    during registration, or publishing communications on Wi-flix products or
                    services, or on the "Your account" or "User" page, according to Wi-flix, from
                    time to time, configure the form of user access; They will include notices about
                    your account (eg, payment authorizations, package selection, change of password
                    or Payment Method, confirmation emails and other information regarding
                    transactions) that are part of your relationship with Wi-flix. You agree that
                    any notice, agreement, disclosure or other communication that we send you
                    electronically will comply with the legal requirements applicable to
                    communications, that they provide, among other things, that said communications
                    must be made in writing. You should keep copies of electronic communications by
                    printing them or saving an electronic copy. You also agree to receive some
                    communications from us, such as news about Wi-flix's new features, special
                    offers, promotions, promotional announcements, advertising and customer surveys
                    by email or by other means. If you no longer want us to send you
                    non-transactional communications, simply follow the established or to be
                    established procedure on the Wi-flix website to manage your communications with
                    Wi-flix. Please see our Privacy Policy for more details about our marketing
                    communications. that such communications must be made in writing. You should
                    keep copies of electronic communications by printing them or saving an
                    electronic copy. You also agree to receive some communications from us, such as
                    news about Wi-flix's new features, special offers, promotions, promotional
                    announcements, advertising and customer surveys by email or by other means. If
                    you no longer want us to send you non-transactional communications, simply
                    follow the established or to be established procedure on the Wi-flix website to
                    manage your communications with Wi-flix. Please see our Privacy Policy for more
                    details about our marketing communications. that such communications must be
                    made in writing. You should keep copies of electronic communications by printing
                    them or saving an electronic copy. You also agree to receive some communications
                    from us, such as news about Wi-flix's new features, special offers, promotions,
                    promotional announcements, advertising and customer surveys by email or by other
                    means. If you no longer want us to send you non-transactional communications,
                    simply follow the established or to be established procedure on the Wi-flix
                    website to manage your communications with Wi-flix. Please see our Privacy
                    Policy for more details about our marketing communications. You also agree to
                    receive some communications from us, such as news about Wi-flix's new features,
                    special offers, promotions, promotional announcements, advertising and customer
                    surveys by email or by other means. If you no longer want us to send you
                    non-transactional communications, simply follow the established or to be
                    established procedure on the Wi-flix website to manage your communications with
                    Wi-flix. Please see our Privacy Policy for more details about our marketing
                    communications. You also agree to receive some communications from us, such as
                    news about Wi-flix's new features, special offers, promotions, promotional
                    announcements, advertising and customer surveys by email or by other means. If
                    you no longer want us to send you non-transactional communications, simply
                    follow the established or to be established procedure on the Wi-flix website to
                    manage your communications with Wi-flix. Please see our Privacy Policy for more
                    details about our marketing communications. If you no longer want us to send you
                    non-transactional communications, simply follow the established or to be
                    established procedure on the Wi-flix website to manage your communications with
                    Wi-flix. Please see our Privacy Policy for more details about our marketing
                    communications. If you no longer want us to send you non-transactional
                    communications, simply follow the established or to be established procedure on
                    the Wi-flix website to manage your communications with Wi-flix. Please see our
                    Privacy Policy for more details about our marketing communications.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>5. Membership or subscription, Free Trials, Billing and Cancellation.</b>
                </P>
                <br />
                <br />
                <P className="terms-p-style">Membership or subscription:</P>
                <br />
                <br />
                <P className="terms-p-style">
                    Membership or subscription continues. Your Wi-flix membership or subscription,
                    which can start with a free trial period (Freemium Package), will continue
                    daily/weekly and/or monthly depending on the opted subscription plan until you
                    cancel your membership or subscription or we do it. You must have Internet
                    access and a current supported and valid form of payment as stated during the
                    subscription (which may be updated from time to time by Wi-flix) to use Wi-flix
                    products or services. We will invoice the monthly membership or subscription fee
                    through your Payment method. You must cancel your membership or subscription
                    prior to daily, weekly or monthly renewal to avoid being billed for the
                    following month's membership or subscription fee through your Payment Method.
                </P>
                <P className="terms-p-style">
                    Differences in memberships or subscriptions. Wi-flix reserves the right to offer
                    a variety of membership or subscription plans, including special promotion plans
                    or membership or subscriptions with different conditions and limitations. Any
                    material that differs from the terms herein described in these Terms and
                    Conditions of Use will be presented to you during the subscription process or in
                    another type of communication to which you will have access. Specific details
                    about your Wi-flix membership or subscription can be found by visiting our
                    website ( http://www.wi-flix.com ). Some of these promotional memberships or
                    subscriptions are or will be offered through third parties along with the
                    provision of their own products and services. We are not responsible for the
                    products and services provided by said third parties. We reserve the right to
                    modify,
                </P>
                <P className="terms-p-style">
                    Billing: Recurring billing. When you start your Wi-flix membership or
                    subscription, you authorize us to charge you a daily, weekly or monthly,
                    membership or subscription fee at the rate in effect at that time (for the
                    entire calendar month, regardless of the day of the month the subscription is
                    made), or as Wi-flix offers at the time, and any other charges incurred in
                    connection with the use you make of Wi-flix products or services through the
                    Payment method. You acknowledge that the amount billed per day/week/month may
                    vary from time to time for reasons that may include different amounts due to
                    promotional offers and / or the change or addition of a plan, and you authorize
                    us to collect these variable amounts through your Form of payment, which could
                    be billed daily, weekly or monthly on one or more charges.
                </P>
                <P className="terms-p-style">
                    Changes in prices We reserve the right to change the prices of our service or
                    any component of the price and / or the Method of payment, in the manner and at
                    the time we determine appropriate, in our sole and complete discretion. Unless
                    otherwise stated in these Terms and Conditions of Use, any change in the price
                    of our service will be effective once you are notified via email.
                </P>
                <P className="terms-p-style">
                    Billing cycle Membership or subscription of the service will be billed at the
                    end of the free trial period, or at the time of subscribing, as Wi-flix defines
                    discretionary and offers at the time, and every month thereafter, unless and
                    until you cancel your membership or subscription. We will automatically bill
                    your Payment Method every month on the calendar day corresponding to the start
                    of your membership or subscription. Membership or subscription fees are fully
                    charged at the time of payment. We reserve the right to change billing times,
                    especially as indicated below if your payment method is not established. If your
                    membership or subscription starts on a day that is not included in a certain
                    month, We may invoice your Payment Method one day in the applicable month or
                    another day as we deem appropriate. For example, if you started your Wi-flix
                    membership or subscription or become a paid member as of January 31, your next
                    payment date will likely be February 28 and your Payment Form will be billed on
                    that date. Your renewal date may change due to changes in your membership or
                    subscription. We may authorize your Payment Form in advance in the event of
                    membership or subscription or changes related to products or services. As used
                    in these Terms and Conditions of Use, "billing" will indicate a charge, debit,
                    or other payment settlement, as applicable, through your Payment Method. Unless
                    otherwise provided, the terms month or monthly refer to your billing cycle. For
                    example, if you started your Wi-flix membership or subscription or become a paid
                    member as of January 31, your next payment date will likely be February 28 and
                    your Payment Form will be billed on that date. Your renewal date may change due
                    to changes in your membership or subscription. We may authorize your Payment
                    Form in advance in case of membership or subscription or changes related to
                    products or services. As used in these Terms and Conditions of Use, "billing"
                    will indicate a charge, debit, or other payment settlement, as applicable,
                    through your Payment Method. Unless otherwise provided, the terms month or
                    monthly refer to your billing cycle. For example, if you started your Wi-flix
                    membership or subscription or become a paid member as of January 31, your next
                    payment date will likely be February 28 and your Payment Form will be billed on
                    that date. Your renewal date may change due to changes in your membership or
                    subscription. We may authorize your Payment Form in advance in the event of
                    membership or subscription or changes related to products or services. As used
                    in these Terms and Conditions of Use, "billing" will indicate a charge, debit,
                    or other payment settlement, as applicable, through your Payment Method. Unless
                    otherwise provided, the terms month or monthly refer to your billing cycle. Your
                    next payment date will likely be February 28 and your Payment Form will be
                    billed on that date. Your renewal date may change due to changes in your
                    membership or subscription. We may authorize your Payment Form in advance in the
                    event of membership or subscription or changes related to products or services.
                    As used in these Terms and Conditions of Use, "billing" will indicate a charge,
                    debit, or other payment settlement, as applicable, through your Payment Method.
                    Unless otherwise provided, the terms month or monthly refer to your billing
                    cycle. Your next payment date will likely be February 28 and your Payment Form
                    will be billed on that date. Your renewal date may change due to changes in your
                    membership or subscription. We may authorize your Payment Form in advance in the
                    event of membership or subscription or changes related to products or services.
                    As used in these Terms and Conditions of Use, "billing" will indicate a charge,
                    debit, or other payment settlement, as applicable, through your Payment Method.
                    Unless otherwise provided, the terms month or monthly refer to your billing
                    cycle. We may authorize your Payment Form in advance in case of membership or
                    subscription or changes related to products or services. As used in these Terms
                    and Conditions of Use, "billing" will indicate a charge, debit, or other payment
                    settlement, as applicable, through your Payment Method. Unless otherwise
                    provided, the terms month or monthly refer to your billing cycle. We may
                    authorize your Payment Form in advance in the event of membership or
                    subscription or changes related to products or services. As used in these Terms
                    and Conditions of Use, "billing" will indicate a charge, debit, or other payment
                    settlement, as applicable, through your Payment Method. Unless otherwise
                    provided, the terms daily, weekly or monthly refer to your billing cycle.
                </P>
                <P className="terms-p-style">
                    No Refunds PAYMENTS ARE NON-REFUNDABLE AND NO REFUNDS OR CREDITS WILL BE GRANTED
                    FOR PARTIALLY USED PERIODS. You can cancel your sMembership or subscription at
                    any time and for any reason, we may grant a refund, discount, or other
                    consideration to some or all of our members ("credits"). The amount and form of
                    said credits, and the decision to grant them, are subject to our sole and
                    complete discretion. The granting of credits in a case does not entitle you to
                    receive credits in the future in similar cases, nor does it oblige us to grant
                    you credits in the future, in any case.
                </P>
                <P className="terms-p-style">
                    Payment methods You can modify the information corresponding to the Payment
                    method by visiting our website (https://www.wi-flix.com). If the payment cannot
                    be made satisfactorily, due to the expiration date, lack of funds or if you do
                    not edit the information on your Payment method or cancel your account (see
                    "Cancellation"; below), you will continue to be responsible for the amounts
                    pending payment and authorize us to continue billing in the Payment Method, as
                    updated. This could result in a change in billing dates. If you signed up for
                    Wi-flix using a third-party account (for example Mobile Money, iTunes or Google
                    Play ) as a Payment Method and you want to cancel your Wi-flix membership or
                    subscription at any time, including during the free trial, You will have to go
                    to your account of the corresponding third party and deactivate the automatic
                    renewal, or cancel the account of products or services of Wi-flix. You can find
                    the billing information for the Wi-flix membership or subscription by visiting
                    your account of the corresponding third party. In some forms of payment, the
                    issuer of your form of payment may charge you a transnational transaction fee or
                    related fees. Check with your payment method's service provider for information.
                </P>
                <P className="terms-p-style">
                    Cancellation. You can cancel Wi-flix membership or subscription at any time. NO
                    REFUNDS OR CREDITS WILL BE GRANTED FOR MEMBERSHIP OR SUBSCRIPTION PERIODS USED
                    PARTIALLY OR FOR CONTENT NOT VIEWED. To cancel, visit our website
                    (https://www.wi-flix.com/) and follow the cancell ation instructions.
                </P>
                <br />
                <br />
                <P className="terms-p-style">
                    <b>6. Wi-flix Products and Services</b>
                </P>
                <br />
                <P className="terms-p-style">
                    1. You must be 18 years of age or the age of majority in your territory or
                    country to be a member or user(s) of Wi-flix products or services. Those under
                    18 years of age, or applicable age of majority, can use the products or services
                    with the supervision of a parent or guardian, through that person's account and
                    subject to our Terms and Conditions of use, all the sole and exclusive
                    responsibility of said parent or guardian, since it is recognized and accepted
                    by the member or user that Wi-flix can offer content only for adults.
                </P>
                <br />
                <P className="terms-p-style">
                    2. Wi-flix products or services and the content accessed through our service are
                    for personal, non-commercial use only. During Wi-flix membership or
                    subscription, we grant you a limited, non-exclusive, and non-transferable
                    license to access Wi-flix products and services for the purpose of viewing SVOD
                    & AVOD content via our streaming service. Except for such a limited license, no
                    rights or titles will transfer to you.
                </P>
                <br />
                <P className="terms-p-style">
                    3. You can view SVOD & AVOD content through Wi-flix products or services only in
                    the geographical places where we offer our products or services and where the
                    corresponding SVOD content is licensed. The content that may be available to
                    view may vary based on geographic location. Wi-flix will use technologies to
                    verify its geographical location. YOU CAN SEE A MAXIMUM OF FOUR MOBILE AND / OR
                    INTERNET-CONNECTED DEVICES AND THE NUMBER OF DEVICES ON WHICH YOU CAN SEE
                    SIMULTANEOUSLY IS LIMITED. Please visit the relevant information on our website
                    to see the number of devices you can view simultaneously, IF THERE IS NO
                    INFORMATION AVAILABLE IT SHOULD BE UNDERSTOOD THAT THE NUMBER OF DEVICES ON
                    WHICH YOU CAN SEE SIMULTANEOUSLY IS LIMITED TO TWO.
                </P>
                <br />
                <P className="terms-p-style">
                    4. Certain Wi-flix products or services are continually updated, including its
                    content catalog, at Wi-flix's discretion. In addition, we regularly test various
                    aspects of our service, including the website, user interfaces, service levels,
                    plans, promotions and features, availability of SVOD content, delivery and
                    prices. We reserve the right to include or exclude you from those tests without
                    notice. We reserve the right, in our sole and complete discretion, to modify the
                    way we offer products and services and their operation whenever we wish and
                    without notice.
                </P>
                <br />
                <P className="terms-p-style">
                    5. You agree to use Wi-flix products or services, including all features and
                    functionality, in accordance with all applicable laws, rules and regulations,
                    including limitations on public display or any other restrictions on the use of
                    the service or its contents. You agree not to archive, download (except for the
                    download necessary for your personal use), reproduce, distribute, modify,
                    transmit, show, execute, reproduce, duplicate, publish, grant licenses, create
                    derivative works based on products or services, or offer for sale, or use
                    (except where explicitly authorized in these Terms and Conditions of Use)
                    content and information contained in or obtained from Wi-flix products or
                    services through it without prior written consent of Wi-flix or its licensors .
                    You agree not to: avoid, remove, modify, disable, interfere with or circumvent
                    the content protections of Wi-flix products or services; use no, spider, scraper
                    or other automated way to access the Wi-flix service; or decompile, reverse
                    engineer, disassemble the software or other product or process accessed through
                    Wi-flix products or services; introduce code or product in any way or manipulate
                    the content of Wi-flix products or services; or use any method of analysis,
                    extraction or data collection. Likewise, you agree not to upload, post, email or
                    transmit in any other way any material designed to interrupt, destroy or limit
                    the functionality of the computer software,
                </P>
                <br />
                <P className="terms-p-style">
                    6. The availability of SVOD and AVOD content to watch will change periodically
                    and will vary by country. The image quality of SVOD & AVOD content transmitted
                    over the Internet can vary from computer to computer, device to device, and can
                    be affected by various factors, such as its location, available bandwidth, or
                    the speed of your Internet connection. All internet access charges will be at
                    your expense. Ask your Internet provider for information about possible data
                    consumption charges for Internet use. Wi-flix makes no representation or
                    warranty regarding the quality of your viewing experience on your screen. The
                    time it takes to start watching any SVOD AND AVOD content will vary according to
                    various factors, including where you are,
                </P>
                <br />
                <P className="terms-p-style">
                    7. The Wi-flix transmission software is developed by Wi-flix and / or through
                    partners, allies or contractors, and was designed to allow the transmission of
                    content from Wi-flix to existing or existing mobile devices and / or connected
                    to the Internet. This software may vary by device and medium, and functionality
                    may also differ by device. BY USING OUR SERVICE, YOU ACKNOWLEDGE AND AGREE TO
                    THE END USER LICENSE OR LIMITED AUTHORIZATION AND FULLY AND UNRESTRICTED
                    AUTHORIZE TO RECEIVE, WITHOUT MORE NOTICE OR NOTICE, THE UPDATED WI-FLIX AND
                    THIRD-PARTY SOFTWARE VERSIONS. IF YOU DO NOT AGREE TO THE ABOVE TERMS, DO NOT
                    USE OUR SERVICE. WE DO NOT ASSUME ANY LIABILITY OR GUARANTEE THE PERFORMANCE OF
                    THESE DEVICES, OR COMPATIBILITY WITH OUR SERVICE. By using our service, you
                    agree to only use the manufacturer or seller of the device if you have any
                    issues with the device or its compatibility with Wi-flix products or services.
                    In the event that your Wi-flix-ready device is lost or stolen, disable it. If
                    you do not log out or do not deactivate the device, subsequent users will be
                    able to access the Wi-flix service through their account and may access some
                    information from their account. To deactivate a device, follow the instructions
                    on our website. disable it. If you do not log out or do not deactivate the
                    device, subsequent users will be able to access the Wi-flix service through
                    their account and may access some information from their account. To deactivate
                    a device, follow the instructions on our website. disable it. If you do not log
                    out or do not deactivate the device, subsequent users will be able to access the
                    Wi-flix service through their account and may access some information from their
                    account. To deactivate a device, follow the instructions on our website.
                </P>
                <br />
                <P className="terms-p-style">
                    8. We may terminate or restrict your use of our service without compensation or
                    notice if we suspect that you or it is notorious that you, (i) violate any of
                    the Terms and Conditions of Use or (ii) that you use the service illegally or
                    improperly.
                </P>
                <br />
                <P className="terms-p-style">
                    9. Members or subscribers or final beneficiaries of Wi-flix products or services
                    are solely responsible for limiting the level of maturity of SVOD content that
                    can be seen by other members of your household (for example, available to your
                    children). Members or subscribers or final beneficiaries of Wi-flix products or
                    services accept and acknowledge that part of the Linear, AVOD and SVOD content
                    that will be available on Wi-flix may be considered "for adults only".
                </P>
                <br />
                <P className="terms-p-style">
                    <b>7. Password and account access</b>
                </P>
                <br />
                <P className="terms-p-style">
                    1. The member or subscriber or beneficiary who created the Wi-flix account and
                    who is billed for charges through their Payment Method is called the Account
                    Holder for the purposes of these Terms and Conditions of Use. The Account Holder
                    has access and control over the Wi-flix account. Control of the Account Holder
                    is exercised through the use of the Account Holder's password and therefore, to
                    maintain exclusive control, the Account Holder should not disclose his password
                    to anyone. Additionally, if the Account Holder wishes to prohibit any third
                    party (related or not) from contacting Wi-flix in any way and potentially modify
                    the Account Holder's control, the Account Holder shall not disclose the details
                    of the Payment Method. (especially, any data from your credit or debit card, or
                    the email address if you use PayPal) associated with your account. It is your
                    responsibility to update and maintain the veracity and accuracy of the personal
                    information you provide to us regarding your account, under penalty of early
                    termination and cancellation of Wi-flix products or services, in Wi-flix's sole
                    discretion.
                </P>
                <br />
                <P className="terms-p-style">
                    2. Eventually, in order to facilitate access to your account and to better
                    manage Wi-flix products or services, Wi-flix may implement technology that
                    allows us to recognize you as the account owner and give you direct access to
                    your account without asking you to re-enter any password. or other user
                    identification when re-entering the Wi-flix service, which includes access
                    through mobile devices and / or connected to the Internet or the website.
                </P>
                <br />
                <P className="terms-p-style">
                    3. BY SHARING THE PASSWORD FOR WI-FLIX PRODUCTS OR SERVICES OR BY ACCEPTING
                    OTHERS TO ACCESS YOUR ACCOUNT, THE ACCOUNT HOLDER ASSUMES RESPONSIBILITY TO
                    ENSURE THAT USERS (RELATED OR NOT) OBSERVE THE TERMS AND CONDITIONS OF USE AND
                    THE TITLE YOU WILL BE RESPONSIBLE FOR ALL THE ACTS OF SUCH USERS.
                </P>
                <br />
                <P className="terms-p-style">
                    4. You should be very cautious with communications requesting your credit card
                    or other account information. If you provide such information upon such a
                    request, you may be a victim of identity theft. Always access your confidential
                    account information by going directly to the Wi-flix website and never through a
                    hyperlink in an email or any other type of communication, even if it seems
                    official. Wi-flix reserves the right to suspend an account at any time, with or
                    without notice to the member, in order to protect itself and its allied partners
                    or contractors, against what it considers to be fraudulent activity.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>8. Disclaimer of warranties and limitations on liability</b>
                </P>
                <br />
                <P className="terms-p-style">
                    1. WI-FLIX PRODUCTS OR SERVICES AND ALL CONTENT AND ASSOCIATED SOFTWARE OR ANY
                    OTHER FEATURES OR FUNCTIONALITY OF WI-FLIX PRODUCTS OR SERVICES ARE PROVIDED "IN
                    THE CONDITION IN WHICH THEY ARE FOUND", WITH NO WARRANTIES AND NO WARRANTIES OF
                    ANY KIND. WI-FLIX MAKES NO REPRESENTATION, STATEMENT OR WARRANTY THAT WI-FLIX
                    PRODUCTS OR SERVICES MAY BE USED WITHOUT INTERRUPTIONS OR ERRORS. WI-FLIX
                    EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR THE USE OF THE MOBILE AND / OR
                    INTERNET-CONNECTED APPLICATIONS, DEVICES AND SOFTWARE (AMONG OTHER THINGS,
                    REGARDING COMPATIBILITY WITH OUR SERVICE).
                </P>
                <br />
                <P className="terms-p-style">
                    2. TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WI-FLIX, ITS
                    SUBSIDIARIES AND THEIR SHAREHOLDERS, DIRECTORS, EXECUTIVES, EMPLOYEES, PARTNERS,
                    OR LICENSORS BE LIABLE TO US OR INDIVIDUALS OR USERS. FOR ANY SPECIAL,
                    INCIDENTAL, INDIRECT OR REMOTE DAMAGES, OR ANY DAMAGES ARISING.
                </P>
                <br />
                <P className="terms-p-style">
                    3. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
                    LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE
                    SOME OF THE LIMITATIONS MENTIONED IN THIS SECTION MAY NOT AFFECT YOU.
                </P>
                <br />
                <P className="terms-p-style">
                    4. NOTHING IN THESE TERMS WILL AFFECT STATUTORY AND UNCOUNTABLE RIGHTS THAT
                    APPLY TO YOU. If any of the provisions of these Terms and Conditions of Use is
                    declared null, illegal or unenforceable, the validity, legality and application
                    of the remaining provisions will continue in full force.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>9. Gifts and promotions:</b> Wi-flix may offer gift subscriptions for
                    purchase on our websites. In such cases, we will invoice your Payment Method for
                    any gift subscription purchase. The acquisition and / or exchange of gift
                    subscriptions will be carried out in accordance with the terms established by
                    Wi-flix for this purpose, at its discretion. Any promotions or gifts may be used
                    only once and cannot be exchanged for money, resold or combined with any other
                    offer, including the free trial. When promotions are received through an offer
                    from a third party, additional restrictions may apply.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>10. Intellectual property</b>
                </P>
                <br />
                <P className="terms-p-style">
                    Copyright. Wi-flix Products or Services, including all content of Wi-flix
                    products or services, are protected by copyright, trade secret or other local or
                    international intellectual property laws or treaties.
                </P>
                <br />
                <P className="terms-p-style">
                    Brands. Wi-flix is a registered trademark or in the process of being registered
                    by BacTech Solutions Limited herein known as “Wi-flix”
                </P>
                <br />
                <P className="terms-p-style">
                    Patents. Wi-flix, its allies or contractors have acquired rights to any patents
                    that apply to the Products or Services.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>11. Law in force</b>
                </P>
                <br />
                <P className="terms-p-style">
                    For all members, subscribers or beneficiaries of Wi-flix, these Terms and
                    Conditions of Use will be governed and interpreted in accordance with the laws
                    of Ghana and/or Netherlands, without prejudice to any provision of private
                    international law.
                </P>
                <br />
                <P className="terms-p-style">
                    You may also be protected by consumer rights under the laws of your local
                    jurisdiction.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>12. Applications:</b> You may find third-party applications (including, but
                    not limited to, websites, widgets, software, or other software products)
                    ("Applications") that interact with Wi-flix products or services. These
                    Applications can import data related to your Wi-flix account and activity, and
                    also obtain data about you. These Applications are provided information for your
                    benefit or for the benefit of the best provision of Services or offer of
                    Products, and Wi-flix is not responsible for said Applications. THESE
                    APPLICATIONS ARE THE PROPERTY OF OR MANAGED BY THIRD PARTIES THAT ARE NOT
                    RELATED TO OR SPONSORED BY WI-FLIX, AND THEIR USE MAY NOT BE AUTHORIZED WITH OUR
                    SERVICE IN ALL COUNTRIES. USE OF AN APP IS BY YOUR CHOICE AND AT YOUR OWN RISK.
                </P>
                <b />
                <P className="terms-p-style">
                    <b>13. Use of the information presented:</b> Wi-flix has the right to use any
                    comments, information, ideas, concepts, reviews or techniques or any other
                    material contained in any communication you send us ("Return"), including
                    responses to questionnaires or publications through Wi-flix products or
                    services. , including Wi-flix products or services and user interfaces, without
                    consideration, recognition or payment of any kind, for any purpose, such as the
                    development, manufacture and marketing of products and the creation,
                    modification or improvement of products or services of Wi-flix. Furthermore, you
                    agree not to apply any "moral standard" in and to the Return, to the extent
                    permitted by applicable law. Please note that Wi-flix does not accept
                    unsolicited materials or ideas for its SVOD and AVOD content, and is not
                    responsible for the similarity between the content or programming of any medium
                    with the materials or ideas transmitted by Wi-flix. If you decide to send any
                    unsolicited material or ideas, you do so with the understanding that you will
                    not receive any consideration of any kind and waive any action against Wi-flix
                    or its related companies regarding the use of those materials and ideas, even if
                    the material or idea used is significantly similar to the material or idea you
                    presented.
                </P>
                <b />
                <P className="terms-p-style">
                    <b>14. Customer service:</b> If you need assistance with your account, you
                    should go to the Wi-flix website page. There you can find answers to frequently
                    asked questions and information about how to contact a Wi-flix representative.
                    Eventually, Wi-flix may offer to assist you using a remote access assistance
                    tool with which you have full access to your computer. If you do not want us to
                    have this access, you should not authorize assistance through the remote access
                    tool and we will help you in other ways. In the event that there is a conflict
                    between these Terms and Conditions of Use and other sections of our website,
                    these Terms and Conditions of Use will govern.
                </P>
                <b />
                <P className="terms-p-style">
                    <b>15. Domicile and Special Jurisdiction</b>
                </P>
                <br />
                <P className="terms-p-style">
                    1. The member, user, beneficiary or subscriber and Wi-flix agree that any
                    difference, claim or controversy that arises, derived directly or indirectly
                    from Wi-flix products or services, these Terms and Conditions of Use and the
                    Privacy Policies will be resolved in Barbados, the Which is chosen as the
                    exclusive and exclusive domicile of any other, whose Courts agree to submit.
                </P>
                <br />
                <P className="terms-p-style">
                    2. In any case, the member, user, beneficiary or subscriber and Wi-flix agree
                    that any difference, claim or controversy that arises will be sought first
                    through the friendly way and under principles of good faith, for which the
                    parties are granted a period of 30 days from the receipt of the respective
                    written notification.
                </P>
                <br />
                <P className="terms-p-style">
                    3. THE USER AND WI-FLIX AGREE THAT EACH ONE OF THEM WILL FILE CLAIMS AGAINST THE
                    OTHER PARTY ONLY IN THEIR OWN NAME, AND NOT AS ACTOR OR PART OF A GROUP IN A
                    COLLECTIVE OR REPRESENTATIVE ACTION. Likewise, unless you and Wi-flix agree
                    otherwise, the Court, Court or competent Judge, will not be able to accumulate
                    the causes of more than one person with its cause nor will it be able to
                    understand in any representative or collective action.
                </P>
                <br />
                <H3>II. Privacy Policy.</H3>
                <br />
                <P className="terms-p-style">
                    Our Privacy Policy establishes the rules related to the collection, use,
                    disclosure and transmission of user data by the Wi-flix group of companies
                    ("Wi-flix"). By using, visiting or browsing Wi-flix products or services, you
                    accept and consent to the collection, use, disclosure and transmission of user
                    data in accordance with our information practices described below.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>1. Collection and use of information</b>
                </P>
                <br />
                <P className="terms-p-style">
                    Wi-flix receives and stores information, including your personal information,
                    about you and your use of our service. This information is collected in
                    different ways, including when you enter it on our website and from interactions
                    with our products and services. In this Privacy Policy we use the term "personal
                    information" to refer to information that can only be used to identify or
                    contact you. Non-personal information means any information that does not allow
                    a direct association with you. We may collect, use, transfer and disclose
                    non-personal information for any purpose. You cannot choose not to provide
                    personal information that is requested, in general, Most of the personal
                    information we request is necessary to provide our service; the lack of such
                    personal information will prevent us from doing so.
                </P>
                <br />
                <P className="terms-p-style">
                    On some occasions, when interacting with us, we will request certain personal
                    information in order to be able to provide, improve and personalize our
                    products, services and marketing campaigns. For the same reason, we may
                    supplement the personal information you provide us with information that is
                    publicly available and information available from other sources, to the extent
                    permitted by applicable law. Personal information is used for various reasons,
                    to process registration, your orders and payments, and to communicate with you
                    about these and other issues. For example, we may use your email, or other
                    contact methods, to send you news, information and messages about Wi-flix's new
                    features, Wi-flix's special offers, promotions, customer surveys and other
                    correspondence and marketing related to our products and services. If you no
                    longer wish to receive certain communications from us, simply follow the steps
                    outlined on our website. Important: you will not be able to unsubscribe from
                    certain correspondence that you receive from us, such as messages regarding your
                    account operations.
                </P>
                <br />
                <P className="terms-p-style">
                    We conduct or may track all of your interactions with us and record or may
                    record information regarding you and your use of Wi-flix products and services,
                    which includes or may include, among other things, your online activity,
                    selection, Review and qualification of titles, payment and correspondence
                    history, Internet protocol addresses, device types, unique device data (such as
                    unique and configuration identifiers), operating systems and SVOD and AVOD
                    content viewed instantly, and all related activity. We use or may use this
                    information to determine the geographical area where you are, offer you relevant
                    content for your area, enforce our terms (for example, determine if we can offer
                    you a free trial period), to recommend SVOD and AVOD content that we consider
                    you may like, personalize the service and our marketing, keep track of the hours
                    you see instantly, to determine your Internet service provider, help us to
                    respond more quickly and efficiently to inquiries and requests, and to analyze,
                    improve or manage or promote our offer of services, both for you and for other
                    users. We may supplement this information with that provided by other sources,
                    including offline data and your browsing behavior with our advertising on other
                    pages. We also provide general user reviews in compiled or anonymous form to our
                    prospective partners, advertisers and other third parties. We may also disclose
                    and use, anonymously, the ratings of SVOD content, consumer habits, comments,
                    reviews and other information. For example, we use SVOD & AVOD content ratings
                    and consumer behavior to improve our recommendations for you and other users.
                </P>
                <br />
                <P className="terms-p-style">
                    By using Wi-flix products or services, you will have the opportunity or may
                    eventually have the opportunity to post reviews or other information in public
                    forums. Please note that when you post this information, the information becomes
                    public knowledge and is no longer protected by these policies. We are not
                    responsible for the use by third parties of the information that you disclose
                    through our products or services.
                </P>
                <br />
                <P className="terms-p-style">
                    Wi-flix reserves the right to associate with companies whose products we
                    consider may be of interest to our members, subscribers, beneficiaries or users.
                    Together with such partners, we may send you promotional advertisements and
                    web-based advertisements on your behalf, to the extent permitted by applicable
                    law.
                </P>
                <br />
                <P className="terms-p-style">
                    You can connect or eventually you can connect one or more social networks to
                    your Wi-flix account. If you choose to do this, we will collect information from
                    your social media account (s) and use it in our social functions.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>2. Disclosure of personal information</b>
                </P>
                <br />
                <P className="terms-p-style">
                    We may share your personal information with the group of companies related to
                    Wi-flix. Companies related to Wi-flix that have access to your information will
                    follow uniform practices with those described in this Privacy Policy.
                </P>
                <br />
                <P className="terms-p-style">
                    In addition, we use other companies, agents or contractors ("Service Providers")
                    to perform services on our behalf or to help us provide the service. For
                    example, we contract service providers to offer marketing, communications,
                    infrastructure and IT services, which personalize and improve our service,
                    process credit card operations, provide customer service, collect debts, analyze
                    and improve data, including information regarding the interaction of our users
                    with our website, products and services, and they process our consumer surveys.
                    In the course of providing these services and offering our products, these
                    companies, and others, may have access to your information.
                </P>
                <br />
                <P className="terms-p-style">
                    We can offer promotions or programs that require the exchange of information
                    with third parties as a condition to participate. For example, we may partner
                    with companies that offer incentives, such as frequent flyer mileage rewards
                    when you subscribe to or use our service. By carrying out this type of
                    promotion, we may share your name and other information related to the
                    realization of the incentive. By participating in such promotions or programs,
                    you implicitly consent to us sharing your information. Important: We do not
                    control the privacy practices of these third party companies.
                </P>
                <br />
                <P className="terms-p-style">
                    Wi-flix also reserves the right to disclose personal information when we
                    reasonably believe that access, use, preservation, or disclosure of such
                    information is necessary to (a) comply with any applicable legislation,
                    regulation, legal process, or government request, (b ) enforce applicable Terms
                    and Conditions of Use, including investigating possible infringements of them,
                    (c) detect, prevent or address illegal or suspected illegal activities and
                    technical or security issues, or (d) protect from harm against rights, the
                    property or security of Wi-flix, its users or the public, as required or
                    permitted by applicable law. In connection with a reorganization, restructuring,
                    merger or sale, or any other transfer of property,
                </P>
                <br />
                <P className="terms-p-style">
                    <b>3. Share on social networks</b>
                </P>
                <br />
                <P className="terms-p-style">
                    You can choose to share information about your use of Wi-flix products or
                    services, including what you saw, within Wi-flix, as well as on other social
                    media and other communications. Your use of our social functions is subject to
                    our Terms and Conditions of use.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>4. Other sites, platforms and applications</b>
                </P>
                <br />
                <P className="terms-p-style">
                    Wi-flix products or services may be received through platforms or contain links
                    to sites operated by third parties that may have policies regarding the handling
                    of information different from ours. For example, you could access Wi-flix
                    products or services through platforms such as PlayStation systems, Miscrsoft,
                    Google Play, iPad or iPhone and a variety of devices with an existing or
                    existing Internet connection. While we do our best to partner with reputable
                    companies, Wi-flix cannot be held responsible for the information handling
                    practices of other websites and platforms. These websites and platforms have
                    their own privacy statements, notices and Terms and Conditions of use, which we
                    recommend you read carefully. Further,
                </P>
                <br />
                <P className="terms-p-style">
                    <b>5. Children</b>
                </P>
                <br />
                <P className="terms-p-style">
                    You must be 18 years or older to subscribe, use or take advantage of Wi-flix
                    products or services. In some jurisdictions, the age of majority may be more
                    than 18 years of age, in which case you must be old enough to be able to
                    subscribe. While those under the age of 18 may eventually use certain products
                    or services, they may only do so with the participation, supervision, and
                    approval of a parent or guardian, and subject to our Terms and Conditions of
                    Use.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>6. Right of access to your information</b>
                </P>
                <br />
                <P className="terms-p-style">
                    We offer you access and the ability to update a wide variety of information
                    about your account, including contact informatio
                </P>
                <br />
                <P className="terms-p-style">
                    <b>7. Cookies and Internet advertising</b>
                </P>
                <br />
                <P className="terms-p-style">
                    The user, subscriber or member, accepts and acknowledges that Wi-flix and its
                    allies or suppliers of goods and services use cookies and other technologies
                    (such as web beacons or web beacons). As a reference, Wi-flix and its allies or
                    providers of goods and services use or could use cookies and other technologies
                    to make access to Wi-flix products and services easier, to provide or analyze
                    our products and services, to better understand our users. and their tastes and
                    interests, and to distribute and adapt marketing or advertising.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>8. Security</b>
                </P>
                <br />
                <P className="terms-p-style">
                    Wi-flix takes information security seriously and uses reasonable administrative,
                    technical, physical and management measures to protect your personal information
                    from unauthorized access.
                </P>
                <br />
                <P className="terms-p-style">
                    Unfortunately, no security system is 100% secure. Therefore, we cannot guarantee
                    the security of your information and cannot assume responsibility for
                    inappropriate access. By using our products and services, including our website
                    and our user interfaces, or by transmitting information to us by any means, you
                    give your consent for us to communicate with you electronically regarding
                    security, privacy and administrative issues regarding your use of Wi-flix
                    products or services.
                </P>
                <br />
                <P className="terms-p-style">
                    In order to facilitate access to your account and to better manage Wi-flix
                    products or services, Wi-flix implements or may discretionary implement
                    technology that allows us to recognize you as the account holder and give you
                    direct access to your account without asking you to re-enter any password or
                    other user identification when re-entering the Wi-flix service. It is your
                    responsibility to protect the confidentiality of access information to your
                    account and to restrict access to your computer or device through which you
                    access your Wi-flix account. Whenever possible, users of public or shared
                    devices should log out at the end of each visit. If you sell or return a
                    Wi-flix-ready computer or device, you must log out and disable the device before
                    doing so.
                </P>
                <br />
                <P className="terms-p-style">
                    <b>9. Changes to the privacy policy</b>
                </P>
                <br />
                <P className="terms-p-style">
                    We reserve the discretionary right to change our Privacy Policy, Terms and
                    Conditions of Use, practices or terms and conditions of use of social networks
                    or information about cookies and Internet advertising from time to time. Such
                    changes will be effective immediately; however, for current members, such
                    reviews will, unless otherwise indicated, be effective 30 days after
                    publication.
                </P>
                <br />
                <P className="terms-p-style">10. Transmission of information</P>
                <br />
                <P className="terms-p-style">
                    As the Internet is a global environment, the use of the Internet to collect and
                    process information necessarily implies the transmission of data
                    internationally. Therefore, by using Wi-flix you consent and agree to the
                    transmission of your information outside your country of residence to any
                    country where we have facilities or activities with third parties (including,
                    among other things, payment processors, cloud service providers or other IT
                    services, and other companies that provide services to us). You understand that
                    the countries to which we transmit information may have a less comprehensive
                    level of data protection than that of your country.
                </P>
                <br /> <br />
                <P className="terms-p-style">Last Updated: June 01, 2020</P>
            </TextBody>
        </Box>
    );
};

Terms.getInitialProps = async () => {
    await Promise.resolve();
};

Terms.getChunkName = () => {
    return 'Terms';
};

const TermsRoute = withRoot(Terms);

export { TermsRoute };
