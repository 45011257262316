export enum Network {
    Safaricom = 'Safaricom',
    VODAFONE = 'Vodafone',
    AIRTEL = 'Airtel',
    AIRTEL_NG = 'Airtel_NG',
    MTN = 'MTN',
    MTNN = 'MTNN',
    GLO = 'GLO',
    OTHER = 'other',
}
