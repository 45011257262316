import { createTheme } from '@mui/material';

import { palette } from './palette';
import { typography } from './typography';
import {
    MuiButton,
    MuiIconButton,
    MuiInputBase,
    MuiOutlinedInput,
    MuiPaper,
    MuiTypography,
} from './overrides';

const theme = createTheme({
    palette,
    typography,
    components: {
        MuiButton,
        MuiInputBase,
        MuiOutlinedInput,
        MuiTypography,
        MuiIconButton,
        MuiPaper,
    },
});

export { theme };
