import { styled, Typography } from '@mui/material';

const Title = styled(Typography)(() => ({
    color: '#FFF',
    lineHeight: '1.625!important',
    fontSize: '1.8rem',
    marginBottom: 0,
    marginTop: 0,
    fontWeight: 600,
    paddingBottom: '2rem',
}));

export { Title };
