import { parsePhoneNumber } from '../../client/helpers/phoneNumbers';
import { Country } from '../enums/country';
import { Network } from '../enums/network';
import { HeaderEnrichedAuth } from '../types/headerEnrichedAuth';
import { getNetworkProvider } from './getNetworkProvider';

const getHeaderEnrichedAuth = (search: URLSearchParams): HeaderEnrichedAuth | undefined => {
    const msisdn = search.get('msisdn');
    if (!msisdn) {
        return undefined;
    }

    const phoneNumber = parsePhoneNumber(msisdn);
    const imsi = search.get('imsi');
    if (!imsi) {
        switch (phoneNumber.countryCode) {
            case Country.NIGERIA:
                return {
                    phoneNumber: phoneNumber.value,
                    network: Network.MTNN,
                };
            case Country.ZAMBIA:
                return {
                    phoneNumber: phoneNumber.value,
                    network: Network.MTN,
                };
            case Country.GHANA:
                return {
                    phoneNumber: phoneNumber.value,
                    network: Network.MTN,
                };
            default:
                return {
                    phoneNumber: phoneNumber.value,
                    network: Network.OTHER,
                };
        }
    }

    const networkProvider = getNetworkProvider(imsi);
    return {
        phoneNumber: phoneNumber.value,
        network: networkProvider.network,
    };
};

export { getHeaderEnrichedAuth };
