import { GlobalStyles } from '@mui/material';

const styles = {
    html: {
        WebkitTextSizeAdjust: '100%',
    },
    'html, body': {
        fontSize: 10,
        height: '100%',
        fontFamily:
            '"Poppins",sans-serif,Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans',
    },
    body: {
        maxWidth: '100%',
        margin: 0,
        lineHeight: 1.5,
        padding: 0,
        overflow: 'auto',
    },
    'body, button': {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    a: {
        color: 'hsl(202,40%,55%)',
        background: 'transparent',
        textDecoration: 'none',
    },
    fieldset: {
        minWidth: 0,
        border: 0,
    },
    'fieldset, legend': {
        margin: 0,
        padding: 0,
    },
    ':focus': {
        outline: 0,
    },
    'h1, h2, h3, h4, h5, h6': {
        border: 0,
        outline: 0,
        padding: 0,
        verticalAlign: 'baseline',
    },
    'ul, ol': {
        padding: 0,
    },
    input: {
        lineHeight: 'normal',
    },
    'h1, h2, h3': {
        marginTop: 20,
        marginBottom: 10,
    },
    h3: {
        margin: '-7px 0 16px',
        bottom: -4,
        fontSize: 14,
        lineHeight: '16px',
    },
    'button, html input[type="button"], input[type="reset"], input[type="submit"]': {
        cursor: 'pointer',
    },
    button: {
        overflow: 'visible',
        color: 'inherit',
        font: 'inherit',
        background: 'none',
    },
    'button, input, optgroup, select, textarea': {
        color: 'inherit',
        font: 'inherit',
        margin: 0,
    },
    '[svg-icon]': {
        display: 'inline-block',
        height: 24,
        verticalAlign: 'text-bottom',
        width: 24,
    },
    'svg:not(:root)': {
        overflow: 'hidden',
    },
    '[svg-icon] svg, [svg-icon] img': {
        display: 'block',
        height: 'inherit',
        width: 'inherit',
    },
};

const CssReset = (): JSX.Element => {
    return <GlobalStyles styles={styles} />;
};

export { CssReset };
