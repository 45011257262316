import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { Country } from '../../shared/enums/country';
import { PhoneNumber } from '../../shared/types/phoneNumber';

const prefixCallingCode = (phoneNumber: string): string =>
    phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;

const parsePhoneNumber = (phoneNumber: string): PhoneNumber => {
    const parser = PhoneNumberUtil.getInstance();
    const parsedNumber = parser.parse(prefixCallingCode(phoneNumber));

    return {
        value: parser.format(parsedNumber, PhoneNumberFormat.E164),
        countryCode: parser.getRegionCodeForNumber(parsedNumber) as Country,
    };
};

export { parsePhoneNumber };
