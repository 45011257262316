import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import flags from 'react-phone-number-input/flags';
import { Select, FormControl, SelectChangeEvent, MenuItem, styled } from '@mui/material';

type CountrySelectProps = {
    value: string;
    onChange: (value: string) => void;
};

const Country = styled('span')(() => ({
    marginLeft: 4,
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#a3a9b2',
}));

const CountrySelect = ({ value, onChange }: CountrySelectProps): JSX.Element => {
    return (
        <FormControl
            sx={{
                minWidth: 'unset',
                '& svg': {
                    color: '#FFF',
                    fontSize: '2rem',
                },
            }}
        >
            <Select
                variant="outlined"
                value={value}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                onChange={(event: SelectChangeEvent) => onChange(event.target.value)}
            >
                {getCountries().map(country => {
                    const Flag = flags[country];

                    return (
                        <MenuItem selected={false} key={country} value={country}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <Flag width={18} height={18} />
                            <Country>
                                +{getCountryCallingCode(country)} ({country})
                            </Country>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export { CountrySelect };
