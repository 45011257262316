import { styled, css } from '@mui/material';
import { css as emotionCss, cx } from '@emotion/css';

import { TextField } from '../TextField';
import { InputProps } from '../../../shared/types/inputProps';

const InputContainer = styled('div')(() => ({
    flexGrow: 1,
    fontSize: 14,
}));

const styles = {
    textFieldRoot: {
        display: 'inline-flex',
        outline: 'none',
        minWidth: 176,
        lineHeight: 'initial',
        textAlign: 'inherit' as const,
    },
    nameInput: {
        minWidth: '320px!important',
    },
    disabledInput: {
        opacity: 0.6,
        cursor: 'not-allowed',
        '&:focus,&:active': {
            outline: 'none',
            border: 'none',
        },
    },
    inputFocused: {
        '& fieldset': {
            borderColor: 'transparent!important',
        },
    },
};

const Input = ({ inputProps, ...props }: InputProps): JSX.Element => (
    <InputContainer>
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            inputProps={{
                ...inputProps,
                classes: inputProps?.classes || {
                    root: cx(
                        emotionCss(css(styles.nameInput)),
                        emotionCss(css(styles.textFieldRoot)),
                    ),
                    disabled: emotionCss(css(styles.disabledInput)),
                    focused: emotionCss(css(styles.inputFocused)),
                },
            }}
            variant="outlined"
            rows={4}
        />
    </InputContainer>
);

export { Input };
