import { palette } from '../palette';

export const MuiInputBase = {
    styleOverrides: {
        input: {
            '&::placeholder': {
                opacity: 1,
                color: palette.text.secondary,
            },
        },
    },
};
