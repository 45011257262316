import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

const getValidationSchema = (
    hasCountrySupport: boolean,
    hasUnsupportedNetwork: boolean,
): yup.ObjectSchema<any> =>
    yup.object().shape({
        phoneNumber: yup
            .string()
            .required('Invalid phone number')
            .test(
                'isValidPhoneNumber',
                'Phone number must be a valid international phone number',
                value => {
                    if (!value) return true;

                    return isValidPhoneNumber(value);
                },
            ),
        password: yup
            .string()
            .min(6, 'Password must be at least 6 characters')
            .max(16, 'Password must not be more than 16 characters')
            .required('password is required'),
        confirmPassword: yup.string().when('password', {
            is: (val: string): boolean => !!(val && val.length > 0),
            then: yup.string().oneOf([yup.ref('password')], 'Must match password'),
        }),
        ...(hasCountrySupport && !hasUnsupportedNetwork
            ? {}
            : {
                  fullName: yup
                      .string()
                      .min(3, 'Full name must be at least 3 characters')
                      .required('Full name is required'),
                  email: yup.string().email('Bad email format').required(),
              }),
    });

export { getValidationSchema };
