import { Checkout } from '../../shared/types/checkout';

const getTarget = (checkoutActions?: Checkout): string => {
    const search = new URLSearchParams(checkoutActions || {});

    return search.toString()
        ? `https://checkout.wi-flix.com/?${search.toString()}`
        : 'https://checkout.wi-flix.com';
};

export { getTarget };
