import { CacheProvider } from '@emotion/react';
import * as Sentry from '@sentry/browser';
import { hydrateRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import { After, ensureReady } from 'wi-flix-after';

import { createEmotionCache } from '../shared/createEmotionCache';
import { fragmentContext } from '../shared/helpers/fragmentContext';
import { routes } from './routes';

const cache = createEmotionCache();

ensureReady(routes)
    .then(data => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                dsn: 'https://9d84e277561849f7a66d0b070873ea51@o108854.ingest.sentry.io/5258878',
            });

            TagManager.initialize({
                gtmId: 'GTM-T6V3XP8',
            });
        }

        // @ts-ignore
        const container = document.getElementById('root');
        return hydrateRoot(
            // @ts-ignore
            // eslint-disable-next-line react/jsx-filename-extension
            container,
            // @ts-ignore
            // eslint-disable-next-line react/jsx-filename-extension
            <BrowserRouter>
                <CacheProvider value={cache}>
                    <GoogleReCaptchaProvider reCaptchaKey="6LdjMd0ZAAAAALiRQuIJ02UCpNMQ3rFITo8mEVP3">
                        <fragmentContext.Provider
                            // @ts-ignore
                            value={data?.contextValue}
                        >
                            <After data={data} routes={routes} />
                        </fragmentContext.Provider>
                    </GoogleReCaptchaProvider>
                </CacheProvider>
            </BrowserRouter>,
        );
    })
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
    });

if (module.hot) {
    module.hot.accept();
}
