import { css, styled, TextField as MuiTextField, Theme, useTheme } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { css as emotionCss, cx } from '@emotion/css';

import { RawInputProps } from '../../../shared/types/inputProps';

const InputContainer = styled('div')(() => ({
    flexGrow: 1,
    fontSize: 14,
}));

const useStyles = (theme: Theme) => ({
    textFieldRoot: {
        display: 'inline-flex',
        outline: 'none',
        minWidth: 176,
        lineHeight: 'initial',
        textAlign: 'inherit' as const,
    },
    nameInput: {
        minWidth: '320px!important',
    },
    disabledInput: {
        opacity: 0.6,
        cursor: 'not-allowed',
        '&:focus,&:active': {
            outline: 'none',
            border: 'none',
        },
    },
    inputFocused: {
        '& fieldset': {
            borderColor: 'transparent!important',
        },
    },
    errorText: {
        fontSize: '1.35rem',
        color: theme.palette.error.main,
        fontWeight: 400,
    },
    errorContainer: {
        marginLeft: 0,
    },
});

const RawInputComponent = (
    { endAdornment, ...props }: RawInputProps,
    ref?: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <InputContainer>
            <MuiTextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                type="text"
                inputRef={ref}
                fullWidth
                error={Boolean(props.error)}
                InputProps={{
                    classes: {
                        root: cx(
                            emotionCss(css(styles.nameInput)),
                            emotionCss(css(styles.textFieldRoot)),
                        ),
                        disabled: emotionCss(css(styles.disabledInput)),
                        focused: emotionCss(css(styles.inputFocused)),
                    },
                    endAdornment,
                }}
                FormHelperTextProps={{
                    variant: 'standard',
                    classes: {
                        root: emotionCss(css(styles.errorText)),
                        contained: emotionCss(css(styles.errorContainer)),
                    },
                }}
                variant="outlined"
            />
        </InputContainer>
    );
};

const RawInput = forwardRef(RawInputComponent);

export { RawInput };
