// import Logo from '../../images/logo.png';

import { Box, styled } from '@mui/material';

const styles = {
    root: {
        zoom: 1,
        width: '100%',
        position: 'relative',
    },
    container: {
        display: 'flex',
        alignItems: 'center!important',
        justifyContent: 'center!important',
        minHeight: '100vh!important',
    },
    background: {
        background: '#000000',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
    boxContent: {
        position: 'relative',
    },
    contentWrap: {
        display: 'flex',
        flex: 1,
    },
    orgLogin: {
        flex: 1,
    },
};

const Section = styled('section')(() => ({
    flex: 1,
}));

const ShadowBox = styled('div')(({ theme }) => ({
    border: 0,
    display: 'flex',
    width: '100%',
    color: '#FFF',
    padding: '4rem!important',
    background: '#222222',
    boxShadow: '0 1px 12px rgba(0,0,0,.15)',
    borderColor: '#cbc3d3',
    [theme.breakpoints.up('sm')]: {
        width: '32rem!important',
        minWidth: '48rem!important',
    },
}));

type OnboardingProps = {
    children: JSX.Element;
};

const Page = ({ children }: OnboardingProps): JSX.Element => {
    return (
        <Box sx={styles.root}>
            <Box sx={styles.container}>
                <Box sx={styles.background} />
                <ShadowBox>
                    <Section>
                        <Box sx={styles.boxContent}>
                            <Box sx={styles.contentWrap}>{children}</Box>
                        </Box>
                    </Section>
                </ShadowBox>
            </Box>
        </Box>
    );
};

export { Page };
