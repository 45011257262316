import { ButtonBase, styled } from '@mui/material';

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 128,
    margin: theme.spacing(),
    border: '1px solid #808080',
    [theme.breakpoints.down('xs')]: {
        width: '43% !important',
        height: 100,
    },
    '&:hover, &.focusVisible': {
        zIndex: 1,
        '& .imageBackdrop': {
            opacity: 0.15,
        },
    },
}));

export { StyledButtonBase };
