import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@mui/material';

import { Page } from '../../components/Page';
import { withRoot } from '../../components/Root';
import { FormActions } from '../../components/FormActions/FormActions';
import { SubmitButton } from '../../components/SubmitButton';
import { Title } from '../../components/Title';
import { Subtitle } from '../../components/Subtitle';

type WelcomeState = {
    validity?: number;
};

const Welcome = ({ location }: RouteComponentProps<any>): JSX.Element => {
    const locationState = (location.state || {}) as WelcomeState;
    const validity = locationState.validity ?? 3;

    const loginURL = 'https://www.wi-flix.com/home?auth=login';

    return (
        <Page>
            <Box
                sx={{
                    padding: [0, 1],
                    margin: 2,
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    borderRadius: 0,
                    borderColor: 'success.light',
                    borderWidth: 2,
                }}
            >
                <Title variant="h3">Your Wi-flix account was successfully created.</Title>
                <Subtitle variant="subtitle1">
                    You have been granted free {validity} days Unlimited Access to enjoy
                    uninterrupted entertainment.
                </Subtitle>
                <FormActions>
                    <SubmitButton href={loginURL} fullWidth variant="contained">
                        Ok
                    </SubmitButton>
                </FormActions>
            </Box>
        </Page>
    );
};

Welcome.getInitialProps = async () => {
    await Promise.resolve();
};

Welcome.getChunkName = () => {
    return 'Welcome';
};

const WelcomeRoute = withRoot(Welcome);

export { WelcomeRoute };
