import { Checkout } from '../types/checkout';

const getCheckout = (search: URLSearchParams): Checkout | undefined => {
    const action = search.get('action') || '';
    const planId = search.get('plan') || '';
    let clickId = search.get('click_id') || search.get('clickId') || '';
    const variant = search.get('variant') || '';
    const promotional = search.get('promotional') || '';
    const affiliate = search.get('pubid') || search.get('affiliate') || '';
    const offerId = search.get('offer_id') || '';

    if (!!affiliate && !!offerId) {
        clickId = `${offerId}::${affiliate}`;
    }

    if (!clickId && !planId) return undefined;

    // only return keys that have values, remove keys with empty strings as values
    return {
        ...(action && { action }),
        ...(planId && { plan: planId }),
        ...(clickId && { clickId }),
        ...(variant && { variant }),
        ...(promotional && { promotional }),
    };
};

export { getCheckout };
