import { styled } from '@mui/material';

import { Country } from '../../../shared/enums/country';
import { Network as NetworkEnum } from '../../../shared/enums/network';
import GLOLogo from '../../images/glo_nigeria.png';
import MTNLogo from '../../images/mtn_ghana.png';
import MTNZambiaLogo from '../../images/mtn_zambia.png';
import SafaricomLogo from '../../images/safaricom.png';
import { ImageBackdrop } from './ImageBackdrop';
import { Network } from './Network';
import { UnsupportedNetwork } from './UnsupportedNetwork';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: theme.spacing(2),
}));

const NetworkWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
    flexWrap: 'wrap',
}));

type NetworkInfo = {
    network: NetworkEnum;
    imageSRC: string;
    countryCode: Country;
};

const networks: Array<NetworkInfo> = [
    {
        network: NetworkEnum.MTN,
        imageSRC: MTNLogo,
        countryCode: Country.GHANA,
    },
    {
        network: NetworkEnum.GLO,
        imageSRC: GLOLogo,
        countryCode: Country.NIGERIA,
    },
    {
        network: NetworkEnum.MTNN,
        imageSRC: MTNLogo,
        countryCode: Country.NIGERIA,
    },
    {
        network: NetworkEnum.MTN,
        imageSRC: MTNZambiaLogo,
        countryCode: Country.ZAMBIA,
    },
    {
        network: NetworkEnum.Safaricom,
        imageSRC: SafaricomLogo,
        countryCode: Country.KENYA,
    },
];

const createCountryCodeFilter = (countryCode: string) => (network: NetworkInfo) =>
    network.countryCode.toLocaleLowerCase() === countryCode.toLocaleLowerCase();

type NetworkPickerProps = {
    countryCode: string;
    onSelectNetwork: (network: string, countryCode: string) => void;
};

const NetworkPicker = ({ countryCode, onSelectNetwork }: NetworkPickerProps): JSX.Element => {
    const countryCodeFilter = createCountryCodeFilter(countryCode);

    if (!countryCode) {
        return <></>;
    }

    return (
        <Container>
            <NetworkWrapper>
                {networks.filter(countryCodeFilter).map(
                    (network: NetworkInfo, index: number): JSX.Element => (
                        <Network
                            onClick={() => onSelectNetwork(network.network, network.countryCode)}
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            imageSrc={network.imageSRC}
                        />
                    ),
                )}

                <UnsupportedNetwork
                    onClick={() => onSelectNetwork(NetworkEnum.OTHER, countryCode)}
                    focusRipple
                    focusVisibleClassName=".focusVisible"
                >
                    <ImageBackdrop className=".imageBackdrop" />
                    Other
                </UnsupportedNetwork>
            </NetworkWrapper>
        </Container>
    );
};

export { NetworkPicker };
