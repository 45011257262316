import { withRoot } from '../../components/Root';
import { Box, Container, H1, H3, Logo, P, TextBody } from '../../components/TermsAndPolicy';
import logo from '../../images/logo.png';

const Policy = (): JSX.Element => {
    return (
        <Box>
            <Container>
                <Logo src={logo} alt="Logo" />
            </Container>
            <TextBody>
                <H1>Privacy Policy</H1>
                <P className="policy-p-style">
                    This Privacy Policy describes how your personal information is collected, used,
                    and shared when you visit or make a subscription from Wi-flix.com (the “Site”).
                </P>
                <br />
                <H3>PERSONAL INFORMATION WE COLLECT</H3>
                <P className="policy-p-style">
                    When you visit the Site, we automatically collect certain information about your
                    device, including information about your web browser, IP address, time zone, and
                    some of the cookies that are installed on your device. Additionally, as you
                    browse the Site, we collect information about the individual web pages or
                    products that you view, what websites or search terms referred you to the Site,
                    and information about how you interact with the Site. We refer to this
                    automatically-collected information as “Device Information.”
                </P>
                <br />
                <P className="policy-p-style">
                    We collect Device Information using the following technologies:
                </P>
                <br />
                <br />
                <P className="policy-p-style">
                    “Cookies” are data files that are placed on your device or computer and often
                    include an anonymous unique identifier. For more information about cookies, and
                    how to disable cookies, visit http://www.allaboutcookies.org.
                </P>
                <br />
                <P className="policy-p-style">
                    “Log files” track actions occurring on the Site, and collect data including your
                    IP address, browser type, Internet service provider, referring/exit pages, and
                    date/time stamps.
                </P>
                <br />
                <P className="policy-p-style">
                    “Web beacons,” “tags,” and “pixels” are electronic files used to record
                    information about how you browse the Site
                </P>
                <br />
                <P className="policy-p-style">
                    Additionally when you make a subscription or attempt to make a subscription
                    through the Site, we collect certain information from you, including your name,
                    email, phone number, payment information (including credit card numbers, email
                    address, and phone number. We refer to this information as “Order Information.”
                </P>
                <br />
                <P className="policy-p-style">
                    When we talk about “Personal Information” in this Privacy Policy, we are talking
                    both about Device Information and Order Information.
                </P>
                <br />
                <P className="policy-p-style">
                    <b>HOW DO WE USE YOUR PERSONAL INFORMATION?</b>
                </P>
                <br />
                <P className="policy-p-style">
                    We use the Order Information that we collect generally to fulfill any
                    subscriptions placed through the Site (including processing your payment
                    information, and providing you with invoices and/or order confirmations).
                    Additionally, we use this Order Information to:
                </P>
                <br />
                <P className="policy-p-style">Communicate with you;</P>
                <br />
                <P className="policy-p-style">Screen our orders for potential risk or fraud; and</P>
                <br />
                <P className="policy-p-style">
                    We use the Device Information that we collect to help us screen for potential
                    risk, and more generally to improve and optimize our Site (for example, by
                    generating analytics about how our customers browse and interact with the Site,
                    and to assess the success of our marketing and advertising campaigns).
                </P>
                <br />
                <P className="policy-p-style">
                    <b>SHARING YOUR PERSONAL INFORMATION</b>
                </P>
                <br />
                <P className="policy-p-style">
                    We share your Personal Information with third parties to help us use your
                    Personal Information, as described above. For example, we use Revidd to power
                    our apps. We also use Analytics to help us understand how our customers use the
                    Site.
                </P>
                <br />
                <P className="policy-p-style">
                    Finally, we may also share your Personal Information to comply with applicable
                    laws and regulations, to respond to a subpoena, search warrant or other lawful
                    request for information we receive, or to otherwise protect our rights.
                </P>
                <br />
                <P className="policy-p-style">
                    <b>DO NOT TRACK</b>
                </P>
                <br />
                <P className="policy-p-style">
                    Please note that we do not alter our Site’s data collection and use practices
                    when we see a Do Not Track signal from your browser.
                </P>
                <br />
                <P className="policy-p-style">
                    <b>YOUR RIGHTS</b>
                </P>
                <br />
                <P className="policy-p-style">
                    If you are a European resident, you have the right to access personal
                    information we hold about you and to ask that your personal information be
                    corrected, updated, or deleted. If you would like to exercise this right, please
                    contact us through the contact information below.
                </P>
                <br />
                <P className="policy-p-style">
                    Additionally, if you are a European resident we note that we are processing your
                    information in order to fulfill contracts we might have with you (for example if
                    you make a subscription through the Site), or otherwise to pursue our legitimate
                    business interests listed above. Additionally, please note that your information
                    will be transferred outside of Europe, including to Canada and the United
                    States.
                </P>
                <br />
                <P className="policy-p-style">
                    <b>DATA RETENTION</b>
                </P>
                <br />
                <P className="policy-p-style">
                    When you place an order through the Site, we will maintain your Order
                    Information for our records unless and until you ask us to delete this
                    information.
                </P>
                <br />
                <P className="policy-p-style">
                    <b>CHANGES</b>
                </P>
                <br />
                <P className="policy-p-style">
                    We may update this privacy policy from time to time in order to reflect, for
                    example, changes to our practices or for other operational, legal or regulatory
                    reasons.
                </P>
                <br />
                <P className="policy-p-style">
                    <b>CONTACT US</b>
                </P>
                <br />
                <P className="policy-p-style">
                    For more information about our privacy practices, if you have questions, or if
                    you would like to make a complaint, please contact us by email at
                    support@wi-flix.com or by mail using the details provided below:
                </P>
                <br />
                <P className="policy-p-style">
                    Wi-flix B.V., Siriusdreef 17, 2132 WT, Hoofddorp, The Netherlands.
                </P>
                <br />
            </TextBody>
        </Box>
    );
};

Policy.getInitialProps = async () => {
    await Promise.resolve();
};

Policy.getChunkName = () => {
    return 'Policy';
};

const PolicyRoute = withRoot(Policy);

export { PolicyRoute };
