import { palette } from '../palette';

export const MuiIconButton = {
    styleOverrides: {
        root: {
            color: palette.icon,
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
            },
        },
    },
};
