import { ChangeEvent } from 'react';
import { TextField as MuiTextField, FormHelperText, useTheme, Theme, css } from '@mui/material';
import { css as emotionCss } from '@emotion/css';

import { InputProps } from '../../../shared/types/inputProps';

const useStyles = (theme: Theme) => ({
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing(0.5),
        },
    },
    textFieldInput: {
        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    textFieldFormLabel: {
        position: 'relative' as const,
        zIndex: 'auto',
        transition: 'z-index .75s steps(1,end)',
    },
    errorText: {
        fontSize: '1.35rem',
        color: theme.palette.error.main,
        fontWeight: 400,
    },
    errorContainer: {
        marginLeft: '0!important',
    },
});

type TextFieldProps = InputProps & {
    label?: string;
    rows?: number;
    classes?: any;
};

const TextField = ({
    label,
    error,
    onChange,
    value,
    name,
    inputRef,
    autoComplete,
    type = 'text',
    required,
    fullWidth,
    multiline,
    onBlur,
    onSubmit,
    disabled,
    rows,
    placeholder,
    inputProps,
}: TextFieldProps) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    let errors: string[] = [];
    if (typeof error === 'string' && error !== '') {
        errors.push(error);
    } else if (Array.isArray(error)) {
        errors = errors.concat(error.map(err => err));
    }

    const hasError = Boolean(errors.length);

    const onFieldChange = (evt: ChangeEvent<HTMLInputElement>) =>
        onChange(name, evt.currentTarget.value, evt);

    return (
        <MuiTextField
            type={type}
            autoComplete={autoComplete}
            label={label}
            onChange={onFieldChange}
            inputRef={inputRef}
            required={required}
            fullWidth={fullWidth}
            multiline={multiline}
            onBlur={onBlur}
            onSubmit={onSubmit}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
                value,
                classes: inputProps?.classes || {
                    root: emotionCss(css(styles.textFieldRoot)),
                    input: emotionCss(css(styles.textFieldInput)),
                },
            }}
            helperText={errors.map(
                (err: string, index: number): JSX.Element => (
                    <FormHelperText
                        classes={{
                            error: emotionCss(css(styles.errorText)),
                            contained: emotionCss(css(styles.errorContainer)),
                        }}
                        style={{ display: 'block' }}
                        component="span"
                        variant="standard"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        error
                    >
                        {err}
                    </FormHelperText>
                ),
            )}
            error={hasError}
            InputLabelProps={{
                shrink: true,
                className: emotionCss(css(styles.textFieldFormLabel)),
            }}
        />
    );
};

export { TextField };
