import { Request } from 'express';

type AuthCookie = 'refreshToken' | 'refresh_token' | '__session';

type RequestCookies = Record<AuthCookie, string>;

const isAuthenticated = (req?: Request): boolean => {
    if (!req) {
        return false;
    }

    const requestCookies = req.cookies as RequestCookies;

    return (
        !!requestCookies?.refreshToken &&
        // eslint-disable-next-line no-underscore-dangle
        (!!requestCookies?.refresh_token || !!requestCookies?.__session)
    );
};

export { isAuthenticated };
