import { Box, Typography } from '@mui/material';

type Error500Props = {
    children?: JSX.Element;
};

const Error500 = ({ children }: Error500Props): JSX.Element => (
    <Box
        sx={{
            padding: 3,
            paddingTop: '10vh',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            background: '#FFF',
        }}
    >
        <Typography align="center" variant="h4">
            Oops, something went wrong!
        </Typography>
        <Typography align="center" variant="subtitle1">
            We&apos;re investigating this issue.
        </Typography>
        {children && (
            <Box
                sx={{
                    marginTop: 6,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {children}
            </Box>
        )}
    </Box>
);

export { Error500 };
