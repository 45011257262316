import { Affiliate } from '../../shared/types/createUser';

const getAffiliateFromURLSearchParams = (search: URLSearchParams): Affiliate | undefined => {
    const agentCode = search.get('ac');
    const sessionId = search.get('sess');
    const nonce = search.get('nonce');
    if (!nonce || !sessionId || !agentCode) {
        return undefined;
    }

    return {
        agentCode,
        sessionId,
        nonce,
    };
};

export { getAffiliateFromURLSearchParams };
