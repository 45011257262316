import { styled } from '@mui/material';
import { ReactNode } from 'react';

import { ButtonContainer } from '../ButtonContainer';

const Container = styled('fieldset')(() => ({
    marginTop: '24px!important',
    background: 'none',
}));

type FormActionsProps = {
    children: ReactNode;
};

const FormActions = ({ children }: FormActionsProps): JSX.Element => {
    return (
        <Container>
            <ButtonContainer>{children}</ButtonContainer>
        </Container>
    );
};

export { FormActions };
