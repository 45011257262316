import { CookieOptions } from 'express';

import { AuthSession } from '../types/authSession';

const DEFAULT_REFRESH_TOKEN_EXPIRY_DAYS = 180;

type Cookie = {
    name: string;
    value: string;
    options: CookieOptions;
};

const getCookieOptions = (referer: string, expiresMillis: number): CookieOptions => {
    const options: CookieOptions = {
        httpOnly: true,
        path: '/',
        secure: false,
        expires: new Date(expiresMillis),
    };

    const isLocalhost = referer.includes('localhost');
    if (!isLocalhost) {
        options.domain = '.wi-flix.com';
        options.secure = true;
    }

    return options;
};

const getDefaultTokenExpiry = (): number => {
    const currDate = new Date();
    const tokenValiditySeconds = DEFAULT_REFRESH_TOKEN_EXPIRY_DAYS * 86400;

    return new Date(currDate.setSeconds(currDate.getSeconds() + tokenValiditySeconds)).getTime();
};

const getAuthCookies = (authSession: AuthSession, referer: string): Cookie[] => {
    const cookies = [
        {
            name: 'refresh_token',
            value: authSession.refresh_token,
            options: getCookieOptions(
                referer,
                authSession.refresh_token_expires || getDefaultTokenExpiry(),
            ),
        },
    ];

    if (authSession.session) {
        cookies.push({
            name: '__session',
            value: authSession.session,
            options: getCookieOptions(referer, Number(authSession.session_expires)),
        });
    }

    const externalAccessTokens = authSession.external_access_tokens;
    if (externalAccessTokens) {
        Object.keys(externalAccessTokens).forEach(provider => {
            const authToken = externalAccessTokens[provider];

            cookies.push({
                name: `${provider}_refresh_token`,
                value: authToken.refresh_token,
                options: getCookieOptions(referer, Number(authToken.refresh_token_expires)),
            });

            // Exception for revidd
            if (provider === 'revidd') {
                cookies.push({
                    name: `refreshToken`,
                    value: authToken.refresh_token,
                    options: getCookieOptions(referer, Number(authToken.refresh_token_expires)),
                });
            }
        });
    }

    return cookies;
};

export { getCookieOptions, getAuthCookies };
