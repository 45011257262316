import { styled } from '@mui/material';

const ButtonContainer = styled('div')(() => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& button': {
        flex: 1,
        marginRight: 24,
        '&:last-of-type': {
            marginRight: 0,
        },
    },
}));

export { ButtonContainer };
